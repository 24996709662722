
import moment from '../plugins/moment';

const helpers = {};

helpers.isActive = (site) => {
    if(!site.schedules || !site.schedules.length) {
        return true;
    }
    const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
    for(const schedule of site.schedules) {
        const openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        const closeTime = (schedule.close_weekday * 24 * 60) + (schedule.close_hours * 60) + schedule.close_minutes;
        if(openTime <= closeTime && openTime <= currentTime && currentTime <= closeTime) {
            return true;
        }
        if(openTime > closeTime && (openTime <= currentTime || currentTime <= closeTime)) {
            return true;
        }
    }
    return false;
};

helpers.closeUntil = (site) => {
    const weekdays = {
        1: 'понедельника',
        2: 'вторника',
        3: 'среды',
        4: 'четверга',
        5: 'пятницы',
        6: 'субботы',
        0: 'воскресенья',
    };

    if(site.schedules && site.schedules.length) {
        const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
        let index = 0;
        let schedule = site.schedules[index];
        let openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        while(openTime < currentTime && site.schedules[index]) {
            schedule = site.schedules[index];
            openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
            index++;
        }
        if(openTime < currentTime) {
            schedule = site.schedules[0];
        }
        
        return `${ weekdays[schedule.open_weekday] } ${ schedule.open_hours.toString().length === 1 ? '0' + schedule.open_hours : schedule.open_hours }:${ schedule.open_minutes.toString().length === 1 ? '0' + schedule.open_minutes : schedule.open_minutes }`;
    }
    return '';
};

helpers.openAt = (site) => {
    const weekdays = {
        1: 'в понедельник в ',
        2: 'во вторник в ',
        3: 'в среду в',
        4: 'в четверг в',
        5: 'в пятницу в',
        6: 'в субботу в',
        0: 'в воскресенье в',
    };

    if(site.schedules && site.schedules.length) {
        const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
        let index = 0;
        let schedule = site.schedules[index];
        let openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        while(openTime < currentTime && site.schedules[index]) {
            schedule = site.schedules[index];
            openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
            index++;
        }
        if(openTime < currentTime) {
            schedule = site.schedules[0];
        }
        
        return `${ weekdays[schedule.open_weekday] } ${ schedule.open_hours.toString().length === 1 ? '0' + schedule.open_hours : schedule.open_hours }:${ schedule.open_minutes.toString().length === 1 ? '0' + schedule.open_minutes : schedule.open_minutes }`;
    }
    return '';
};

export default helpers;