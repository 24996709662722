<template>
    <div class="site-card" @click="$router.push(`/${ site.code }`)">
        <div class="site-card__picture" :style="site.picture ? `background-image: url(${imageSrc(site.picture)})` : ''">
            <div class="site-card__gradient"></div>
            <div class="site-card__title">{{ site.title }}</div>
            <div class="site-card__close" v-if="!isActive">Откроется {{openAt}}</div>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '../../helpers';
    import helpersEntity from '../../helpers/site';

    export default {
        name: 'EntityCard',
        props: {
            site: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            imageSrc
        }),
        computed: {
            isActive() {
                return helpersEntity.isActive(this.site);
            },
            openAt() {
                return helpersEntity.openAt(this.site);
            }
        }
    }
</script>

<style lang="scss">
.site-card {
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;

    &__picture {
        position: relative;
        width: 100%;
        height: 250px;
        padding: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.66;
        background: linear-gradient(225deg, rgba(26, 0, 0, 0) 35.77%, #1A0000 90.7%);
        border-radius: 24px;
    }
    
    &__title {
        color: #FFFFFF;
        display: -webkit-box;
        overflow: hidden;
        position: relative;
        word-break: break-word;
        text-shadow: 0px 6px 12px rgb(13 50 62 / 15%);
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.03em;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 14px 20px;
        font-size: 16px;
        line-height: 20px;
        border-radius: 16px;
        background: #FFFFFF;
    }
}
</style>