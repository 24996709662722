<template>
    <div class="sites">
        <SiteCard
            v-for="site in sites" 
            :key="`site-${site.id}`"
            :site="site"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import SiteCard from '../../components/site/SiteCard';

    export default {
        name: 'SitesList',
        metaInfo() {
            return {
                title: 'Партнеры'
            }
        },
        components: {
            SiteCard
        },
        data: () => ({
            
        }),
        computed: {
            ...mapState('sites', {
                sites: state => state.entities
            }),
        },
        async mounted() {
            store.commit('sites/CLEAR_ENTITY');
            await store.dispatch('sites/fetch', {});
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .sites {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);

        @media screen and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
</style>
